import { useAuthStore } from '~/store/auth.store'

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore()

  if (authStore.isAuthenticated) {
    return navigateTo({
      name: 'main',
      query: to.query
    })
  }
})
